<template>
  <div class="d-block fill-height neutral">
    <logo-group
      class="px-6 py-6"
      :logomark="true"
      :logotype="true"
    />
    <div class="ma-auto mini-wides white--text">
      <v-container
        fluid
      >
        <v-row>
          <v-col
            cols="12"
            class="terms-page"
          >
            <ul v-if="false">
              <li
                :class="{activeTerms: isActive('terms') }"
                class="termLinks"
                @click.stop="selectedTerms('terms'), currentTerms = '' , current = true"
              >
                Terms of Service
              </li>
              <li
                v-if="oldTerms && oldTerms.length"
                :class="{activeTerms: isActive('archive') }"
                class="termLinks"
                @click.stop="selectedTerms('archive'), currentTerms = '' , current = false"
              >
                Updates
              </li>
            </ul>
            <div v-if="!current">
              <h1
                v-if="currentTerms === ''"
                style="text-align: center; margin-top: 2rem;"
              >
                Updates: Terms of Service
              </h1>
              <ul v-if="currentTerms === ''">
                <li
                  v-for="(termsLink, index) in oldTerms"
                  :key="index"
                  class="termLinks"
                  @click="currentTerms = termsLink"
                >
                  {{ formatDate.terms(termsLink.createdAt) }}
                </li>
              </ul>
              <h1
                v-if="!current && currentTerms !== ''"
                style="text-align: center; margin-top: 2rem;"
              >
                Update: {{ formatDate.terms(currentTerms.createdAt) }}
              </h1>
              <!-- eslint-disable -->
            <div
              v-if="currentTerms && currentTerms.terms"
              v-html="currentTerms.terms"
            />
            <!-- eslint-enable -->
            </div>
            <div v-if="current">
              <h1 style="text-align: center; margin-top: 2rem;">
                Terms of Service
              </h1>
              <h3 style="text-align: center;">
                {{ formatDate.terms(termsDate) }}
              </h3>
              <br>
              <!-- eslint-disable -->
            <div v-html="terms" />
            <!-- eslint-enable -->
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import LogoGroup from '@/common/LogoGroup.vue'
import { formatDate } from '@/config/formatDate.js'

export default {
  name: 'OuterTerms',
  components: {
    LogoGroup
  },
  metaInfo () {
    return {
      title: this.brandName
    }
  },
  data () {
    return {
      formatDate,
      active: 'terms',
      current: true,
      terms: '',
      oldTerms: [],
      currentTerms: '',
      termsDate: new Date()
    }
  },
  beforeMount () {
    this.$http.get('terms/application').then(res => {
      if (res.data) {
        this.terms = res.data.terms
        this.termsDate = res.data.createdAt
        // this.oldTerms = res.data.terms.old
      }
    }, err => {
      console.error(err)
    })
  },
  methods: {
    isActive (activeChoice) {
      return activeChoice === this.active
    },
    selectedTerms (type) {
      this.active = type
      if (this.oldTerms.length === 1) {
        this.currentTerms = this.oldTerms[0]
      }
    }
  }
}
</script>
